import React from "react";

import "./style/Medikamente.css";

export default function Medikamente(props){

  const medElements = props.data.map(m => (
    <div className="Medikamente--box">
      <h1>{m.time}</h1>
      <p className="Medikamente--text">{m.dosis}</p>
    </div>
))

  return(
    <div className="Medikamente">
      <h1>Medikamente</h1>
      {medElements}
      
      <button className="input input-button" onClick={(event)=>props.viewChange('main')}>Zurück</button>
    </div>
  )
}