import React from 'react';

import Login from './components/Login';
import Menu from './components/Menu';
import Bericht from './components/Bericht';
import BerichtLesen from './components/BerichtLesen';
import Medikamente from './components/Medikamente';
import Einkaufsliste from './components/Einkaufsliste';
import Dienstplan from './components/Dienstplan';

function App() {

  const [menuIdx, setMenuIdx] = React.useState({
    state:"login"//"Bericht Lesen"
  })

  const [info, setInfo] = React.useState()

  function setView(viewName){
    setMenuIdx(x => ({
        ...x,
        state:viewName
    }))
  }

  // React.useEffect(()=>{
  //   
  // ,[])
   
  const useLogin = async (value) => {
    if(localStorage.getItem('sessionKey') !== null && info === undefined){
      value = localStorage.getItem('sessionKey')
    }
    
    if(value === 'none'){return;}
    console.log(value)

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({ password: value, type: 'login'})
    };
    try {
      console.log("request");
      
      let response = await fetch('https://dennisscheerer.de:6019/PflegeScheerer/Request', requestOptions);
      const data = await response.json()
      setInfo(data);


      if(data['info'] !== undefined){
        setView('main');
        localStorage.setItem('sessionKey',value)
      }

    } catch(err){
      console.error(err.message);
    }
  }

  function refresh(val){
    setInfo(val)
    setView('main');
  }

  
  useLogin("none")
  return (
    <div className="App">
      {console.log(menuIdx.state)}
      {menuIdx.state === "login" && <Login  viewChange={setView} handleLogin={useLogin} />}
      {menuIdx.state === "main" && <Menu viewChange={setView} data={info.info}/>}
      {menuIdx.state === "Bericht einreichen" && <Bericht viewChange={setView} userInfo={info.info} refresh={refresh}/>}
      {menuIdx.state === "Bericht lesen" && <BerichtLesen viewChange={setView} data={info.reports}/>}
      {menuIdx.state === "Medikamente" && <Medikamente viewChange={setView} data={info.medication}/>}
      {menuIdx.state === "Einkaufsliste" && <Einkaufsliste viewChange={setView} data={info.shopping}/>}
      {menuIdx.state === "Dienstplan" && <Dienstplan viewChange={setView} data={info.timesheet}/>}
    </div>
  );
}

export default App;
