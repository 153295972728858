import React from "react";

import "./style/Bericht.css";
import DateInput from "./date_component/DateInput";

export default function Bericht(props){
  
  let now = new Date();
  
  const [reportData, setReportData] = React.useState(
    {
    startDate:{
      day:now.getUTCDate(),
      month:now.getMonth()+1,
      year:now.getFullYear()
    },
    endDate:{
      day:now.getUTCDate(),
      month:now.getMonth()+1,
      year:now.getFullYear()
    },
    report:""
  })

  function setDate(dateType,area,value){
    setReportData(prevData =>{
      prevData[dateType][area] = value
      return(
          {
            ...prevData
          }
        );
      }
    )
  }

  function updateReportText(event){
    const {value} = event.target
    setReportData(prev => ({
      ...prev,
      report:value
    }))
  }

  const sendReport = async () => {


    let value = {
      password:props.userInfo.pw,
      type:'report',
      color:props.userInfo.color,
      startdate:`${reportData.startDate.day}.${reportData.startDate.month}.${reportData.startDate.year}`,
      enddate:`${reportData.endDate.day}.${reportData.endDate.month}.${reportData.endDate.year}`,
      bericht:encodeURIComponent(reportData.report),
      pflege:encodeURIComponent(props.userInfo.name)
    }
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(value)
    };

    try {      
      let response = await fetch('https://dennisscheerer.de:6019/PflegeScheerer/Request', requestOptions);
      const data = await response.json()
      props.refresh(data)
    } catch(err){
      console.error(err.message);
    }
  }

  return(
    <div className="Bericht">
      <DateInput title="Einsatz von:" dateType="startDate" dateChange={setDate} value={reportData.startDate}/>
      <DateInput title="Einsatz bis:" dateType="endDate" dateChange={setDate} value={reportData.endDate}/>
      <div className="Bericht--container">
        <h2>Bericht erstellen:</h2>
        <textarea  
          className="Bericht--text" 
          onChange={updateReportText} 
          value={reportData.report}
        />
      </div>
      <div className="Bericht--buttons">
      <button className="input input-button" onClick={(event)=>props.viewChange('main')}>Zurück</button>
      <button className="input input-button" onClick={(event)=>sendReport()}>Absenden</button>
      </div>
    </div>
  )
}