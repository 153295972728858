import React from "react";

import "./DateInput.css";
import Dial from "./Dial";

export default function DateInput(props){
 
  return(
    <div className="DateInput">
      <h2>{props.title}</h2>
      <div>
      <Dial dateType={props.dateType} area="day" range={[1,31]} dateChange={props.dateChange} value={props.value.day}/>
      <Dial dateType={props.dateType} area="month" range={[1,12]} dateChange={props.dateChange} value={props.value.month}/>
      <Dial dateType={props.dateType} area="year" range={[2022,2300]} dateChange={props.dateChange} value={props.value.year}/>
      </div>
    </div>
  )
}