import React from "react";

import "./../style/BerichtLesen.css";

export default function BerichtEintrag(props){
  
  return(
    <div className="berichteintrag">
      <hr id="breakline"/>
      <div className="Menu--userCircle berichteintrag--circle " style={{backgroundColor: `#${props.color}`}}></div>
      <p className="berichteintrag--date">{props.startdate} - {props.enddate}</p>
      <p className="berichteintrag--text">{props.text}</p>
      <p className="berichteintrag--name">{props.name}</p>
    </div>
  )
}