import React from "react";

import "./style/Dienstplan.css";

export default function Dienstplan(props){

  

  return(
    <div className="Dienstplan">
      <h1>Mein Dienstplan</h1>
      
      <button className="input input-button" onClick={(event)=>props.viewChange('main')}>Zurück</button>
    </div>
  )
}