import React, {useState} from "react";

import "./style/Login.css";

export default function Login(props){
  
  const [creds, setCreds] = React.useState({
    pw:""
  })

  function handleChange(event){
    const {value} = event.target;
    setCreds(prevState => {
      return {
        pw: value
      }
    })
  }

  return(
    <div className="login">
      <div className="login--icon--circle">
        <img className="login--icon--icon" src="/symbols/keyIcon.png" />
      </div>
      <input 
        className="input input-text"
        type="password"
        value={creds.pw}
        onChange={handleChange}
        name="password"
      />
      <button className="input input-button" onClick={(event)=>props.handleLogin(creds.pw)}>Anmelden</button>
    </div>
  )
}