import React from "react";

import "./style/BerichtLesen.css";

import BerichtEintrag from "./undercomponents/BerichtEintrag";

export default function BerichtLesen(props){
  const einträge = props.data.map(rep => (
    <BerichtEintrag 
        key={rep.id} 
        startdate={rep.startdate} 
        enddate={rep.enddate}
        text={decodeURIComponent(rep.bericht)}
        name={decodeURIComponent(rep.pflege)}
        color={rep.color}
    />
))

return(
    <div className="BerichtLesen">
       {einträge}
      <button className="input input-button" onClick={(event)=>props.viewChange('main')}>Zurück</button>
    </div>
  )
}