import React from "react";

import "./style/Einkaufsliste.css";

export default function Einkaufsliste(props){
  
  const list = props.data.map(x => (
    <tr>
      <th class="rb cs">{x.article}</th>
      <th class="cl rb">{x.quantity}</th>
      <th class="cl">{x.shop}</th>
    </tr>
  ))

  return(
    <div className="Einkaufsliste">
      <h1>Einkaufsliste</h1>
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <th class="rb db cs"><h2>Lebensmittel</h2></th>
          <th class="rb db cl"><h2>Menge</h2></th>
          <th class="db cl"><h2>Laden</h2></th>
        </thead>
        {list}
      </table>
      <button className="input input-button" onClick={(event)=>props.viewChange('main')}>Zurück</button>
    </div>
  )
}