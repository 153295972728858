import React from "react";

import "./style/Menu.css";

import MenuButton from "./undercomponents/MenuButton";

export default function Menu(props){
  return(
    <div className="Menu">
      <div className="Menu--userCircle" style={{backgroundColor: `#${props.data.color}`}}>
        <h1 className="Menu--name">{props.data.name}</h1>
      </div>
      <MenuButton title="Bericht einreichen" icon="Bericht" viewChange={props.viewChange} isActive={true}/>
      <MenuButton title="Bericht lesen" icon="BerichtLesen" viewChange={props.viewChange} isActive={true}/>
      <MenuButton title="Medikamente" icon="Medikamente" viewChange={props.viewChange} isActive={true} />
      <MenuButton title="Einkaufsliste" icon="Warenkorb" viewChange={props.viewChange} isActive={true}/>
      <MenuButton title="Dienstplan" icon="Event" viewChange={props.viewChange} isActive={true}/>
    </div>
  )
}