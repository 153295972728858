import React from "react";


export default function Dial(props){
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  return(
    <div className="Dial">
      <button onClick={(event)=>props.dateChange(props.dateType,props.area,clamp(props.value + 1,props.range[0],props.range[1]))}><img src="./symbols/Arrow.svg" /></button>
      <h1>{props.value}</h1>
      <button onClick={(event)=>props.dateChange(props.dateType,props.area,clamp(props.value - 1,props.range[0],props.range[1]))} id="DialButtonDown"><img src="./symbols/Arrow.svg" stlye="" /></button>
    </div>
  )
}